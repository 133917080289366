import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import type { LocationQueryValue } from 'vue-router'
import type { ItemLayout } from '@/types/ui'

/**
 * Merge Tailwind classes with clsx
 * @param inputs Class values for clsx to merge
 * @returns Merged Tailwind classes
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

/**
 * Get the first value of possibly multiple query values
 * @param queryValue The query value(s) to convert
 * @returns The first query value
 */
export const getFirstQueryValue = (
  queryValue: LocationQueryValue | LocationQueryValue[]
) => (Array.isArray(queryValue) ? queryValue[0] : queryValue)

/**
 * Check if a value matches the `ItemLayout` type.
 * If so, narrow the type.
 * @param value The value to check
 * @returns Whether the value matches the ItemLayout type
 */
export const isItemLayout = (value: any): value is ItemLayout =>
  value === 'row' || value === 'card'
